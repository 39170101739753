class {

    onCreate() {
        this.state = {
            categoryCount: [],
            categoryCountByName: [],
            filteredCategory: ''
        };
    }

    onInput(input) {
        this.state.filteredCategory = input.filteredCategory
        
        let counts = []
        for (const todo of input.todos) {
            let categoryName = todo.category
            if (categoryName !== undefined && categoryName !== '' && !counts.some(e => e.name === categoryName)) {
                counts.push({ name: categoryName, count: 0})
            }
            
            const index = counts.findIndex(e => e.name === categoryName)
            
            if (index !== -1) {
                counts[index].count++
            }
        }

        this.state.categoryCount = counts

        const categoryCountByName = counts.sort((a, b) => {
            if (a.name < b.name)
              return -1
            if (a.name > b.name)
              return 1
            return 0
        })

        this.state.categoryCountByName = categoryCountByName
    }

    handleEdit(todoId) {
        this.emit("todo-edit", todoId)
    }

    handleDelete(todoId) {
        this.emit("todo-delete", todoId)
    }

    handleComplete(todoId, event) {
        this.emit("todo-toggle-complete", todoId, event.target.checked)
    }

    handleFilterClick(categoryName) {
        this.emit("toggle-filter-category", categoryName)
    }
}

<ul class="no-list-style">
    <for|category| of=state.categoryCountByName>
        <li key=category.name on-click("handleFilterClick", category.name) class=`filter-item${state.filteredCategory === category.name ? ' active-filter' : ''}`>
            ${category.name} (${category.count})
        </li>
    </for>
</ul>